<template>
    <div>
        <div class="padding">
            <el-page-header :title="$t('返回')" @back="goBack" :content="$t('成绩单分析')">
            </el-page-header>
        </div>
        <el-card class="margin-t" style="max-width: 1000px; margin:0 auto;">

            <div class="padding margin-t">



                <div class="flex margin-t  flex-v-center">
                    <div class="flex1">
                        <el-button size="small" type="primary" round icon="el-icon-edit"
                            @click="$refs.goalDialog.show()">
                            {{ $t('学习目标') }}
                        </el-button>
                    </div>


                    <div :style="{ 'border-bottom': index < 3 ? '1px solid #e6a13c' : '', 'border-top': index < 3 ? '1px solid #e6a13c' : '', 'border-left': index == 0 ? '1px solid #e6a13c' : '', 'border-top-left-radius': index == 0 ? '5px' : '', 'border-bottom-left-radius': index == 0 ? '5px' : '' }"
                        class="flex1 flex flex-v-center" v-for="(item, index) in ['口语', '写作', '阅读', '听力', '总分']"
                        :key="index">
                        <div style="padding:10px 15px;"
                            :style="{ 'border-left': index == 4 ? '1px solid #e6a13c' : '', 'border-bottom': (index == 3 || index == 4) ? '1px solid #e6a13c' : '', 'border-top': (index == 3 || index == 4) ? '1px solid #e6a13c' : '', 'border-right': (index == 3 || index == 4) ? '1px solid #e6a13c' : '', 'border-top-right-radius': '5px', 'border-bottom-right-radius': '5px', 'border-top-left-radius': index == 4 ? '5px' : '', 'border-bottom-left-radius': index == 4 ? '5px' : '', }">
                            <span>{{ $t(item) }}：</span>
                            <span>{{ exam_data[index] }}</span>
                        </div>
                    </div>
                </div>

                <div class="margin-t">
                    <div class="flex flex-h-center">
                        <el-divider>
                            <div class="bold center mt">{{ $t('录入成绩') }}</div>
                        </el-divider>
                    </div>
                    <el-form :inline="true" ref="form" label-position="top" :model="form">
                        <div class="flex">
                            <div class="flex1">
                                <el-form-item :label="$t('时间')"
                                    :rules="[{ required: true, message: $t('请选择日期'), trigger: 'blur' }]" prop="day">
                                    <el-date-picker v-if="!$store.state.reload" value-format="yyyy-MM-dd" style="width: inherit;" v-model="form.day"
                                        type="date" :placeholder="$t('选择日期')">
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item :label="$t('口语')" prop="s"
                                    :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.s" :placeholder="$t('口语分数')"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item :label="$t('写作')" prop="w"
                                    :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.w" :placeholder="$t('写作分数')"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item :label="$t('阅读')" prop="r"
                                    :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.r" :placeholder="$t('阅读分数')"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item :label="$t('听力')" prop="l"
                                    :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.l" :placeholder="$t('听力分数')"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item :label="$t('总分')" prop="t"
                                    :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input :max="90" :min="10" v-model="form.t" :placeholder="$t('总分分数')"></el-input>
                                </el-form-item>
                            </div>
                            <!-- 
                            <div class="flex flex-h-end">
                                <el-form-item label="⁡ ">
                                    <el-button type="primary" @click="submit">提交</el-button>
                                </el-form-item>
                            </div> -->

                        </div>


                    </el-form>
                </div>

            </div>
            <div class="bold mt color-primary margin-l">Sub-skills Score</div>
            <el-table class="margin-ls" :show-header="false" :data="tableData" style="width: 100%">
                <el-table-column prop="label">

                </el-table-column>

                <el-table-column prop="day" label="">
                    <template slot-scope="scope">
                        <div>
                            <div style="display: flex; gap: 10px;flex-wrap: wrap;justify-content: flex-end;">
                                <span v-for="(tag, index1) in tags[scope.$index].split(',')"
                                    :key="index1">{{
                                        tag }}</span>
                            </div>
                            <div>
                                <el-slider v-model="scope.row.value" :max="90"></el-slider>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="value" width="100px">

                </el-table-column>
            </el-table>

            <div class="flex flex-h-center padding margin-t">
                <el-button type="primary" round icon="el-icon-document-checked" @click="submit">{{ $t('提交分析')
                    }}</el-button>
            </div>
        </el-card>
        <goalDialog ref="goalDialog"></goalDialog>
        <transcript-dialog ref="transcriptDialog"></transcript-dialog>
    </div>
</template>

<script>

import goalDialog from '../../components/GoalDialog.vue';
import transcriptDialog from '../../components/TranscriptDialog.vue';


export default {
    components: {
        goalDialog, transcriptDialog
    },
    data() {
        return {
            checkInput: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error(this.$t('分数不能为空')));
                }
                setTimeout(() => {
                    if (isNaN(Number(value)) || value < 10 || value > 90) {
                        callback(new Error(this.$t('输入10-90的整数')));
                    } else {
                        callback();
                    }
                });
            },
            form: { s: '', w: '', r: '', l: '', t: '', day: '' },
            items: [
                'Open Response Speaking and Writing',
                'Reproducing Spoken and Written Language',
                'Extended Writing',
                'Short Writing',
                'Extended Speaking',
                'Short Speaking',
                'Multiple-skills Comprehension',
                'Single-skill Comprehension'
            ],
            tags: ['WE,DI,RL', 'RA,RS,RL,SST,SWT,WFD,FIB-L', 'WE,SWT,SST', 'WFD,FIB-RW,FIB-L', 'DI,RL', 'RA,RS,ASQ', 'WFD,RS,RL,SST,FIB-L,HCS,HIW,ASQ,RA,SWT,FIB-RW', 'MCM-L,MCS-L,SMW,FIB-R,RO,MCM-R,MCS-R'],
            tableData: []
        };
    },

    computed: {
        exam_data() {
            return this.$store.state.user.exam_data || {};
        }
    },

    created() {
        if (this.$route.query.day) {
            this.form.day = this.$route.query.day
            this.form.s = this.$route.query.s
            this.form.w = this.$route.query.w
            this.form.r = this.$route.query.r
            this.form.l = this.$route.query.l
            this.form.t = this.$route.query.t
        }
        this.tableData = this.items.map(item => {
            return { label: item, value: 0 }
        })
    },

    methods: {
        goBack() {
            this.$router.back();
        },
        toPlan() {
            this.$router.replace('/main/plan')
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const keys = ['s', 'w', 'r', 'l', 't']
                    for (let i = 0; i < keys.length; i++) {
                        if (isNaN(Number(this.form[keys[i]])) || this.form[keys[i]] < 0 || this.form[keys[i]] > 90) {
                            this.$message.warn(this.$t('分数在0到90之间'))
                            return
                        }
                    }
                    this.form.items = this.tableData.map(item => {
                        return item.value
                    })

                    this.$http.post("/api/exam/score/save", this.form).then(() => {
                        this.$message({
                            message: this.$t('提交成功'),
                            type: 'success'
                        });
                    })

                    this.$refs.transcriptDialog.show(this.form)

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },

    },
};
</script>
<style scoped></style>
